<template>
  <div id="ztws-events-create">
    <v-overlay :value="ProgressLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4>
      </v-card-subtitle>

      <v-card-text>
        <v-container class="notes" v-if="NotesFlag">
          <p>Kindly note:</p>
          <ol>
            <li>Regular subscription can be paid only once.</li>
            <li>
              After paying regular subscription, choose additional subcription.
            </li>
            <li>
              Late fee is optional but paying late fee is required to get voting
              rights.
            </li>
            <li>
              After completing the payment, kindly select your members for whom
              the payment was made.
            </li>
          </ol>
        </v-container>
        <v-row wrap>
          <v-col align="start" cols="12" md="12">
            <div class="notes-content">
              <p><span class="text-danger">*</span> indicates required field</p>
            </div>
          </v-col>
        </v-row>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container v-if="StartupLoadingFlag">
          <v-row wrap>
            <v-col cols="12" md="4" v-for="n in 6" :key="n">
              <v-skeleton-loader
                v-bind="attrs"
                type="paragraph"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-if="!StartupLoadingFlag">
          <v-dialog v-model="addEducationDetailsPrompt" persistent width="auto">
            <add-education-details
              pageDescription="Add education details"
              :addEducationDetailsPrompt="addEducationDetailsPrompt"
              @hideAddRecordPrompt="addNewRow($event)"
              v-if="addEducationDetailsPrompt"
            ></add-education-details>
          </v-dialog>
          <v-dialog v-model="addRecordPrompt" persistent max-width="80%">
            <upload-profile-picture
              pageTitle="Change Profile Picture"
              pageDescription=""
              :addRecordPrompt="addRecordPrompt"
              :currentPhoto="Member.MemberImage"
              @hideAddRecordPrompt="changeProfilePhoto"
              v-if="addRecordPrompt"
            ></upload-profile-picture>
          </v-dialog>
          <v-form
            ref="form1"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="confirmAlert"
          >
            <v-card elevation="3" outlined>
              <v-card-title class="mycard-title-1 fsize-4 fcolor-1 bg-color-2"
                >Personal Details</v-card-title
              >
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6><span class="text-danger">*</span> Member Name</h6>
                    </label>
                    <h3>{{ Member.Fullname }}</h3>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    lg="3"
                    md="3"
                    justify="center"
                    align="center"
                  >
                    <h6 class="mycard-text1 fsize-3">Member Image</h6>
                    <v-img
                      v-if="Member.MemberImage != ''"
                      width="100"
                      height="100"
                      :src="Member.MemberImage"
                    />
                    <br />
                    <v-icon
                      icon
                      size="25px"
                      color="primary"
                      @click.prevent="addRecordPrompt = true"
                    >
                      mdi-cloud-upload
                    </v-icon>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6><span class="text-danger">*</span> Member DOB</h6>
                    </label>
                    <v-dialog
                      ref="dialog1"
                      v-model="DateModal1"
                      :return-value.sync="Member.DOB"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="Member.DOB"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="Member.DOB"
                        scrollable
                        :max="DateMax"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="DateModal1 = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialog1.save(Member.DOB)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Dates Since JCI
                        member
                      </h6>
                    </label>
                    <v-dialog
                      ref="dialog2"
                      v-model="DateModal2"
                      :return-value.sync="Member.JoiningDate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="Member.JoiningDate"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="Member.JoiningDate"
                        scrollable
                        :max="DateMax"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="DateModal2 = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialog2.save(Member.JoiningDate)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6>
                        Website
                        <small>(optional)</small>
                      </h6>
                    </label>
                    <v-text-field
                      v-model="Website"
                      hint="Enter the Website"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6><span class="text-danger">*</span> Email</h6>
                    </label>
                    <v-text-field
                      v-model="Member.EmailId"
                      :rules="EmailRules"
                      hint="Enter the Email"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6><span class="text-danger">*</span> Phone No</h6>
                    </label>
                    <v-text-field
                      v-model="Member.MobileNo"
                      :rules="MobileNoRules"
                      hint="Enter the Phone No"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6><span class="text-danger">*</span> Street1</h6>
                    </label>
                    <v-text-field
                      v-model="Member.Street1"
                      :rules="Street1Rules"
                      hint="Enter the Street1"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6>
                        Street2
                        <small>(optional)</small>
                      </h6>
                    </label>
                    <v-text-field
                      v-model="Member.Street2"
                      hint="Enter the Street2"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6>
                        Area
                        <small>(optional)</small>
                      </h6>
                    </label>
                    <v-text-field
                      v-model="Member.Area"
                      hint="Enter the Area"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6><span class="text-danger">*</span> State</h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="StateCodeOptionsLoading"
                      :items="StateCodeOptions"
                      :rules="StateCodeRules"
                      v-model="StateCode"
                      required
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6><span class="text-danger">*</span> District</h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="DistrictCodeOptionsLoading"
                      :items="DistrictCodeOptions"
                      :rules="DistrictCodeRules"
                      v-model="DistrictCode"
                      required
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6><span class="text-danger">*</span> City</h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="CityCodeOptionsLoading"
                      :items="CityCodeOptions"
                      :rules="CityCodeRules"
                      v-model="CityCode"
                      dense
                      clearable
                      required
                      outlined
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6><span class="text-danger">*</span> Pincode</h6>
                    </label>
                    <v-text-field
                      v-model="Member.Pincode"
                      :rules="PincodeRules"
                      hint="Enter the Pincode"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <br />
            <br />
            <v-card elevation="3" outlined>
              <v-card-title class="mycard-title-1 fsize-4 fcolor-1 bg-color-2">
                <span class="text-danger">*</span> Education Details
              </v-card-title>
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="12" lg="12" md="12">
                    <v-data-table
                      :headers="tableColumns2"
                      :items="EducationDetails"
                      hide-default-footer
                      disable-sort
                      id="document-table"
                    >
                      <template v-slot:no-data>
                        <v-btn
                          icon
                          color="blue"
                          @click.prevent="addEducationDetailsPrompt = true"
                        >
                          <v-icon>mdi-plus-circle</v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:item.action="{ item, index }">
                        <v-btn
                          icon
                          color="blue"
                          @click.prevent="addEducationDetailsPrompt = true"
                        >
                          <v-icon>mdi-plus-circle</v-icon>
                        </v-btn>
                        <v-btn icon color="red" @click="deleteRow(index, item)">
                          <v-icon>mdi-delete-circle-outline</v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <br />
            <br />
            <v-card elevation="3" outlined>
              <v-card-title class="mycard-title-1 fsize-4 fcolor-1 bg-color-2"
                >Event Details</v-card-title
              >
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> High position held in
                        JCII
                      </h6>
                    </label>
                    <v-text-field
                      v-model="HighestPosition"
                      :rules="HighestPositionRules"
                      hint="Enter the High Position"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> High position held
                        Year
                      </h6>
                    </label>
                    <v-text-field
                      v-model="HighestPositionYear"
                      :rules="HighestPositionYearRules"
                      :maxlength="4"
                      :counter="4"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Your Profession
                      </h6>
                    </label>
                    <v-text-field
                      v-model="ProfessionDetails"
                      :rules="ProfessionRules"
                      hint="Enter the Profession"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6>Failed</h6>
                    </label>
                    <v-switch
                      inset
                      v-model="FailedStatus"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:label>
                        <span v-bind="attrs" v-on="on">
                          {{ FailedStatus ? "Yes" : "No" }}
                        </span>
                      </template>
                    </v-switch>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3" v-if="TrainerTypeFlag">
                    <label>
                      <h6>Trainer Type</h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="TrainerTypeOptionsLoading"
                      :items="TrainerTypeOptions"
                      :rules="TrainerTypeRules"
                      v-model="TrainerType"
                      dense
                      clearable
                      required
                      outlined
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6>
                        Have you attended earlier? if yes, Year
                        <small>(optional)</small>
                      </h6>
                    </label>
                    <v-text-field
                      v-model="AlreadyAttendedYear"
                      hint="Enter the Year"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6>Are you a Transferred Member from other LOM</h6>
                    </label>
                    <v-switch
                      inset
                      v-model="TransferredStatus"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:label>
                        <span v-bind="attrs" v-on="on">
                          {{ TransferredStatus ? "Yes" : "No" }}
                        </span>
                      </template>
                    </v-switch>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    lg="3"
                    md="3"
                    v-if="TransferredStatus"
                  >
                    <label>
                      <h6><span class="text-danger">*</span> Zone</h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="TransferredZoneCodeOptionsLoading"
                      :items="TransferredZoneCodeOptions"
                      v-model="TransferredZoneCode"
                      dense
                      clearable
                      required
                      outlined
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    lg="3"
                    md="3"
                    v-if="TransferredStatus"
                  >
                    <label>
                      <h6><span class="text-danger">*</span> LOM</h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="TransferredLomCodeOptionsLoading"
                      :items="TransferredLomCodeOptions"
                      v-model="TransferredLomCode"
                      dense
                      clearable
                      outlined
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <br />
            <br />
            <v-card elevation="3" outlined>
              <v-card-title class="mycard-title-1 fsize-4 fcolor-1 bg-color-2"
                >Please furnish the details of your Participation in the
                following Training Programs</v-card-title
              >
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="12" lg="12" md="12">
                    <v-dialog
                      ref="dialog3"
                      v-model="DateModal3"
                      persistent
                      width="290px"
                    >
                      <v-date-picker
                        v-model="TrainingDate"
                        scrollable
                        :max="DateMax"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="DateModal3 = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="changePicker(Selected)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>

                    <v-data-table
                      :headers="tableColumns1"
                      :items="TrainingDetails"
                      hide-default-footer
                      disable-sort
                      id="document-table"
                    >
                      <template v-slot:item.Date="{ item, index }">
                        <v-text-field
                          v-model="item.Date"
                          placeholder="Date"
                          readonly
                          @click="openDatePicker(item)"
                          outlined
                          dense
                        ></v-text-field>
                      </template>
                      <template v-slot:item.Host="{ item, index }">
                        <v-text-field
                          required
                          v-model="item.Host"
                          placeholder="Host"
                          outlined
                          dense
                        ></v-text-field>
                      </template>
                      <template v-slot:item.PilotFaculty="{ item, index }">
                        <v-text-field
                          required
                          v-model="item.PilotFaculty"
                          placeholder="Pilot Faculty"
                          outlined
                          dense
                        ></v-text-field>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <br />
            <br />
            <v-card elevation="3" outlined>
              <v-container>
                <v-row wrap>
                  <v-col cols="12">
                    <label>
                      <h6>
                        What are your expectations from this ZTWS
                        <small>(optional)</small>
                      </h6>
                    </label>
                    <v-textarea
                      v-model="ZtwsExpectations"
                      value
                      :maxlength="300"
                      :counter="300"
                      outlined
                      solo
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <label>
                      <h6>
                        How will you contribute as a qualified Zone Trainer ?
                        <small>(optional)</small>
                      </h6>
                    </label>
                    <v-textarea
                      v-model="ContributeDescription"
                      value
                      :maxlength="300"
                      :counter="300"
                      outlined
                      solo
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <br />
            <br />
            <v-dialog v-model="PreviewPlanFlag" width="500">
              <event-plan-details
                :Plan="PreviewPlanDetails"
                @hideDialog="hidePreviewPlanPopup($event)"
                v-if="PreviewPlanFlag"
              ></event-plan-details>
            </v-dialog>
            <v-row justify="center" class="ma-3">
              <div v-for="(item, index) in Plans" :key="index">
                <v-col cols="12" sm="4" md="4" lg="4">
                  <div v-if="item.Recommended">
                    <div class="cards-box" v-if="item.PlanVisibleFlag">
                      <div class="cards popular">
                        <div class="cards-ribbon">
                          <span>most popular</span>
                        </div>
                        <div class="cards-title-bar">
                          <h3 class="cards-title">{{ item.PlanName }}</h3>
                          <h4
                            class="cards-subtitle"
                            v-if="item.PlanDescription != ''"
                          >
                            {{ item.PlanDescription }}
                          </h4>
                        </div>
                        <div class="cards-price">
                          <h4 class="cards-price-amount">
                            <!-- <sup class="cards-price-sypol">&#8377;</sup> -->
                            {{ item.TotalAmountTxt }}
                            <small class="cards-price-validity">per head</small>
                          </h4>
                          <v-divider />
                          <v-btn
                            color="primary"
                            dark
                            @click.prevent="showPreviewPlanPopup(item)"
                            >Show Plan details</v-btn
                          >
                          <p></p>
                        </div>
                        <br />
                        <v-divider />
                        <div class="cards-action">
                          <button
                            class="cards-action-button"
                            :disabled="!valid1"
                            @click.prevent="confirmAlert(item)"
                          >
                            Pay now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="cards-box" v-if="item.PlanVisibleFlag">
                      <div class="cards">
                        <div class="cards-title-bar">
                          <h3 class="cards-title">{{ item.PlanName }}</h3>
                          <h4
                            class="cards-subtitle"
                            v-if="item.PlanDescription != ''"
                          >
                            {{ item.PlanDescription }}
                          </h4>
                        </div>
                        <div class="cards-price">
                          <h4 class="cards-price-amount">
                            <!-- <sup class="cards-price-sypol">&#8377;</sup> -->
                            {{ item.TotalAmountTxt }}
                            <small class="cards-price-validity">per head</small>
                          </h4>
                          <v-divider />
                          <v-btn
                            color="primary"
                            dark
                            @click.prevent="showPreviewPlanPopup(item)"
                            >Show Plan details</v-btn
                          >
                          <p></p>
                        </div>
                        <br />
                        <v-divider />
                        <div class="cards-action">
                          <button
                            class="cards-action-button"
                            :disabled="!valid1"
                            @click.prevent="confirmAlert(item)"
                            :loading="SubmitFlag"
                          >
                            Pay now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </div>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import AddEducationDetails from "@/view/pages/erp/events/nalanda-events/AddEducationDetails";
import UploadProfilePicture from "@/view/pages/profile/my-profile/UploadProfilePicture2.vue";
import EventPlanDetails from "@/view/pages/erp/events/natcon/EventPlanDetails.vue";

export default {
  mixins: [common],
  components: {
    AddEducationDetails,
    UploadProfilePicture,
    EventPlanDetails,
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      StartupLoadingFlag: false,
      ProgressLoadingFlag: false,
      SubmitFlag: false,
      NotesFlag: false,

      uploadPercentage: 0,
      progessStart: 0,

      PreviewPlanFlag: false,
      PreviewPlanDetails: {},

      addRecordPrompt: false,
      addEducationDetailsPrompt: false,

      DateModal1: false,
      DateModal2: false,
      DateModal3: false,
      DateMax: new Date().toISOString().slice(0, 10),

      TrainingDate: "",
      Selected: {},

      EmailRules: [(v) => !!v || "Email is required"],
      MobileNoRules: [(v) => !!v || "Mobile No is required"],
      Street1Rules: [(v) => !!v || "Street1 is required"],
      PincodeRules: [(v) => !!v || "Pincode is required"],
      HighestPositionRules: [(v) => !!v || "Highest Position is required"],
      HighestPositionYearRules: [
        (v) => !!v || "Highest Position Held Year is required",
        (v) =>
          v.length >= 4 || "Highest Position Held Year is required 4 digits",
      ],
      ProfessionRules: [(v) => !!v || "Profession is required"],

      tableColumns1: [
        { text: "Name Of Training", value: "Name" },
        { text: "Date", value: "Date" },
        { text: "Host", value: "Host" },
        { text: "Pilot Faculty", value: "PilotFaculty" },
      ],

      tableColumns2: [
        { text: "Year", value: "YearTxt" },
        { text: "Qualification", value: "QualificationTxt" },
        { text: "Degree", value: "DegreeTxt" },
        { text: "Specilization", value: "SpecilizationTxt" },
        { text: "Institution", value: "Institution" },
        { text: "Action", value: "action" },
      ],

      Member: {},
      EducationDetails: [],
      Website: "",
      TransferredStatus: false,
      HighestPosition: "",
      HighestPositionYear: "",
      ProfessionDetails: "",
      AlreadyAttendedYear: "",
      FailedStatus: false,
      ContributeDescription: "",
      ZtwsExpectations: "",
      Opinion: "",
      TrainingDetails: [
        {
          Name: "CAP/LAP",
          Date: "",
          Host: "",
          PilotFaculty: "",
        },
        {
          Name: "Speechcraft",
          Date: "",
          Host: "",
          PilotFaculty: "",
        },
      ],

      TrainerTypeFlag: false,
      TrainerTypeRules: [],
      TrainerType: "",
      TrainerTypeOptions: [
        { value: "", text: "--Select Trainer--" },
        { value: 0, text: "No Trainer" },
        { value: 1, text: "Zone Trainer" },
        { value: 2, text: "National Trainer" },
      ],
      TrainerTypeOptionsLoading: false,

      TransferredZoneCodeRules: [],
      TransferredZoneCode: "",
      TransferredZoneCodeOptions: [],
      TransferredZoneCodeOptionsLoading: false,

      TransferredLomCodeRules: [],
      TransferredLomCode: "",
      TransferredLomCodeOptions: [],
      TransferredLomCodeOptionsLoading: false,

      StateCodeRules: [(v) => !!v || "State is required"],
      StateCode: "",
      StateCodeOptions: [],
      StateCodeOptionsLoading: false,

      DistrictCodeRules: [(v) => !!v || "District is required"],
      DistrictCode: "",
      DistrictCodeOptions: [],
      DistrictCodeOptionsLoading: false,

      CityCodeRules: [(v) => !!v || "City is required"],
      CityCode: "",
      CityCodeOptions: [],
      CityCodeOptionsLoading: false,

      Events: {},
      Plans: [],
      selectedPlan: {},

      EventId: "",
      EventTypeId: 6,
      ZoneCode: "",
      LomCode: "",
      MemberId: "",
      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      FloatingButtonOptions: {},

      SelectedImage: {
        ImageType: "",
        ImageUrl: null,
      },
      MemberImage: {},
    };
  },
  computed: {},
  mounted() {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var PagePermissionFlag = tr.PagePermissionFlag;
        console.log({ PagePermissionFlag });

        if (!PagePermissionFlag) {
          this.$router.push("/access-denied");
        }

        var PagePermissionTxt = tr.PagePermissionTxt;
        console.log({ PagePermissionTxt });

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        var MemberId = this.$session.get("MemberId");
        this.MemberId = MemberId;
        console.log({ MemberId });

        var ZoneId = this.$session.get("ZoneId");
        this.ZoneCode = ZoneId;
        console.log({ ZoneId });

        var LomId = this.$session.get("LomId");
        this.LomCode = LomId;
        console.log({ LomId });

        this.getZoneCodeOptions();
        this.getMemberDetails();
        this.getEventsDetails();
      }
    },
    TransferredZoneCode: function () {
      console.log("watch TransferredZoneCode");
      this.LoadingFlag = true;
      this.TransferredLomCode = "";
      this.getLomCodeOptions();
    },
    TransferredZoneCodeOptions: function () {
      console.log("watch TransferredZoneCodeOptions");
      this.TransferredZoneCodeOptionsLoading = false;
    },
    TransferredLomCodeOptions: function () {
      console.log("watch LomCodeOptions");
      this.LoadingFlag = false;
      this.TransferredLomCodeOptionsLoading = false;
    },
    Member: function () {
      console.log("watch Member");
      var rows = this.Member;
      var n1 = Object.keys(rows).length;
      // var n1 = this.rows.length;
      console.log("n1=" + n1 + ", rows=" + JSON.stringify(rows));
      if (n1 > 0) {
        var StateCode = rows.StateId;
        console.log({ StateCode });
        this.StateCode = StateCode;
        this.getDistrictCodeOptions();
        var DistrictCode = rows.DistrictId;
        console.log({ DistrictCode });
        this.DistrictCode = DistrictCode;
        this.getCityCodeOptions();
        var CityCode = rows.CityId;
        console.log({ CityCode });
        this.CityCode = CityCode;
      }
    },
    StateCodeOptions: function () {
      console.log("watch StateCodeOptions");
      this.LoadingFlag = false;
      this.StateCodeOptionsLoading = false;
    },
    StateCode: function () {
      console.log("watch StateCode");
      this.LoadingFlag = true;
      this.getDistrictCodeOptions();
    },
    DistrictCodeOptions: function () {
      console.log("watch DistrictCodeOptions");
      this.LoadingFlag = false;
      this.DistrictCodeOptionsLoading = false;
    },
    DistrictCode: function () {
      console.log("watch DistrictCode");
      this.LoadingFlag = true;
      this.getCityCodeOptions();
    },
    CityCodeOptions: function () {
      console.log("watch CityCodeOptions");
      this.LoadingFlag = false;
      this.CityCodeOptionsLoading = false;
    },
  },
  methods: {
    openDatePicker(tr) {
      console.log("openDatePicker called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.Selected = tr;
        this.TrainingDate = tr.Date;
        this.DateModal3 = true;
      } else {
        var message = "Kindly select one data to preview";
        this.toast("error", message);
      }
    },
    changePicker(tr) {
      console.log("changePicker called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var name = tr.Name;
        var idx = this.TrainingDetails.map((e) => e.Name).indexOf(name);
        console.log("idx=" + idx);
        if (idx >= 0) {
          this.TrainingDetails[idx].Date = this.TrainingDate;
          this.DateModal3 = false;
        }
      } else {
        var message = "Kindly select one data to preview";
        this.toast("error", message);
      }
    },
    backPage() {
      this.$router.go(-1);
    },
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    changeProfilePhoto(photo) {
      console.log("changeProfilePhoto called");
      this.addRecordPrompt = false;
      console.log("photo=" + JSON.stringify(photo));
      // this.$refs.ProfilePicture.files[0] = photo;
      if (photo != "") {
        this.Member.MemberImage = photo;
        this.MemberImageFlag = true;
      }
    },
    resetForm() {
      this.Member = {};
      this.MemberId = "";
      this.MembershipId = "";
      this.EducationDetails = [];
      this.Website = "";
      this.TransferredStatus = false;
      this.HighestPosition = "";
      this.HighestPositionYear = "";
      this.ProfessionDetails = "";
      this.AlreadyAttendedYear = "";
      this.FailedStatus = false;
      this.ContributeDescription = "";
      this.ZtwsExpectations = "";
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      this.resetForm();
      var id = this.$route.query.id;
      console.log({ id });
      this.EventId = id;
      var url1 = "api/jci-program/show";
      var condition1 = {
        UserInterface: 1,
        Program: id,
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    showPreviewPlanPopup(tr) {
      this.PreviewPlanDetails = tr;
      this.PreviewPlanFlag = true;
    },
    hidePreviewPlanPopup() {
      this.PreviewPlanDetails = {};
      this.PreviewPlanFlag = false;
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var yearCode = this.JciYearCode;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        this.TransferredZoneCodeOptionsLoading = true;
        var selectbox1_source = "TransferredZoneCode";
        var selectbox1_destination = "TransferredZoneCodeOptions";
        var selectbox1_url = "api/year-wise-lom/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      var YearCode = this.JciYearCode;
      var ZoneCode = this.TransferredZoneCode;
      console.log("YearCode=" + YearCode + ", ZoneCode=" + ZoneCode);
      if (YearCode != "" && ZoneCode != "") {
        this.LoadingFlag = true;
        this.TransferredLomCodeOptionsLoading = true;
        var selectbox1_source = "TransferredLomCode";
        var selectbox1_destination = "TransferredLomCodeOptions";
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 3,
          YearCode: YearCode,
          ZoneCode: ZoneCode,
          AllOption: 0,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getMemberDetails() {
      console.log("getMemberDetails is called");

      var MemberId = this.MemberId;
      var ZoneCode = this.ZoneCode;
      var LomCode = this.LomCode;
      console.log(
        "MemberId=" +
          MemberId +
          "; ZoneCode=" +
          ZoneCode +
          "; LomCode=" +
          LomCode
      );

      if (MemberId != "" && ZoneCode != "" && LomCode != "") {
        this.LoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members/search";
        var upload = {
          UserInterface: 4,
          MemberId: MemberId,
          ZoneCode: ZoneCode,
          LomCode: LomCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.Member = records;
              thisIns.EducationDetails = records.EducationDetails;
              console.log(
                "EducationDetails=" + JSON.stringify(records.EducationDetails)
              );
              thisIns.getStateCodeOptions();
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "";
        if (MemberId == "" || ZoneCode == "" || LomCode == "") {
          message += "Your session is expired. Kindly login again. ";
        }
        this.toast("error", message);
      }
    },
    getEventsDetails() {
      console.log("getEventsDetails is called");

      var EventCode = this.EventId;
      console.log("EventCode=" + EventCode);

      if (EventCode != "") {
        this.LoadingFlag = true;
        this.Events = {};
        this.Plans = [];

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jci-program/plan/lists";
        var upload = {
          UserInterface: 2,
          Program: EventCode,
          ProgramType: this.EventTypeId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              // thisIns.Events = records;
              thisIns.Plans = records.TableData;
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "";
        if (LomId == "") {
          message += "Your session is expired. Kindly login again. ";
        }
        this.toast("error", message);
      }
    },
    getStateCodeOptions() {
      console.log("getStateCodeOptions called");
      this.LoadingFlag = true;
      this.StateCodeOptionsLoading = true;
      var selectbox1_source = "StateCode";
      var selectbox1_destination = "StateCodeOptions";
      var selectbox1_url = "api/states/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getDistrictCodeOptions() {
      console.log("getDistrictCodeOptions called");
      var StateCode = this.StateCode;
      console.log({ StateCode });
      if (StateCode != "") {
        this.LoadingFlag = true;
        this.DistrictCodeOptionsLoading = true;
        var selectbox1_source = "DistrictCode";
        var selectbox1_destination = "DistrictCodeOptions";
        var selectbox1_url = "api/district/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          StatesCode: StateCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getCityCodeOptions() {
      console.log("getCityCodeOptions called");
      var DistrictCode = this.DistrictCode;
      console.log({ DistrictCode });
      if (DistrictCode != "") {
        this.CityCodeOptionsLoading = true;
        var selectbox1_source = "CityCode";
        var selectbox1_destination = "CityCodeOptions";
        var selectbox1_url = "api/city/options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          DistrictCode: DistrictCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    confirmAlert(selectedPlan) {
      console.log("confirmAlert called");
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      var JoiningDate = this.Member.JoiningDate;
      var DOB = this.Member.DOB;
      var MemberImageFlag = this.Member.MemberImageFlag;
      console.log(
        "MemberImageFlag=" +
          MemberImageFlag +
          "; JoiningDate=" +
          JoiningDate +
          "; DOB=" +
          DOB
      );

      var SelectedImage = this.SelectedImage;

      if (!MemberImageFlag) {
        if (SelectedImage.ImageUrl != null && SelectedImage.ImageUrl != "") {
          MemberImageFlag = true;
        }
      }
      console.log("MemberImageFlag=" + MemberImageFlag);

      var flag =
        validate1 &&
        MemberImageFlag &&
        JoiningDate != "" &&
        DOB != "" &&
        JoiningDate != null &&
        DOB != null
          ? true
          : false;
      console.log("flag=" + flag);

      if (flag) {
        var EventPlanId = selectedPlan.EventPlanId;
        EventPlanId = EventPlanId == (null || undefined) ? "" : EventPlanId;
        console.log("EventPlanId=" + EventPlanId);

        this.selectedPlan = selectedPlan;

        var htmlTxt = "";
        htmlTxt += "<br/><b>Selected plan details</b>";
        htmlTxt += "<br/>Plan name <b>" + selectedPlan.PlanName + "</b>";
        htmlTxt +=
          "<br/>Total amount <b>" + selectedPlan.TotalAmountTxt + "</b>";
        htmlTxt += "<br/><br/>Do you want to continue?";
        // htmlTxt += "<br/> Title <b> " + TitleName + "</b>";

        Swal.fire({
          title: "Please verify all the information before proceeding.",
          text: "",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Continue`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.generateRequestData();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        if (!MemberImageFlag) {
          message += "Member image should not be empty. ";
        }
        if (JoiningDate == "" || JoiningDate == null) {
          message += "Member Joining Date should not be empty. ";
        }
        if (DOB == "" || DOB == null) {
          message += "Member DOB should not be empty. ";
        }
        console.log("message=" + message);
        this.sweetAlert("error", message, false);
      }
    },
    flip(x, y) {
      console.log("flip x=" + x + ", y=" + y);
      this.$refs.cropper.flip(x, y);
    },
    rotate(angle) {
      console.log("rotate=" + angle);
      this.$refs.cropper.rotate(angle);
    },
    addImage(id) {
      console.log("addImage called");
      console.log({ id });
      switch (id) {
        case 1:
          var file = this.MemberImage;
          console.log({ file });
          var src = URL.createObjectURL(file);
          console.log({ src });
          if (this.SelectedImage.src) {
            URL.revokeObjectURL(this.SelectedImage.src);
          }
          var FileType = file.type;
          console.log({ FileType });
          this.SelectedImage = {
            ImageType: FileType,
            ImageUrl: src,
            UploadedImage: file,
          };
          break;

        default:
          break;
      }
    },
    deleteImage(id) {
      console.log("deleteImage called");
      console.log({ id });
      switch (id) {
        case 1:
          this.SelectedImage = {
            ImageType: null,
            ImageUrl: null,
          };
          this.MemberImage = {};
          break;

        default:
          break;
      }
    },
    getCroppedImage() {
      console.log("getCroppedImage called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      var selectedPlan = this.selectedPlan;
      var EventPlanId = selectedPlan.EventPlanId;
      EventPlanId = EventPlanId == (null || undefined) ? "" : EventPlanId;
      console.log("EventPlanId=" + EventPlanId);

      var TrainerType = this.TrainerType == "" ? 0 : this.TrainerType;

      if (EventPlanId != "" && validate1) {
        var TotalAmount = parseFloat(selectedPlan.TotalAmount);
        console.log("TotalAmount=" + TotalAmount);

        this.Member.StateId = this.StateCode;
        this.Member.DistrictId = this.DistrictCode;
        this.Member.CityId = this.CityCode;

        var TransferredStatus = this.TransferredStatus ? 1 : 0;
        var FailedStatus = this.FailedStatus ? 1 : 0;

        let upload = new FormData();

        upload.append("UserInterface", 1);
        upload.append("RegistrationType", 3);
        upload.append("Event", this.EventId);
        upload.append("Plan", EventPlanId);
        upload.append("Lom", this.LomCode);
        upload.append("Member", this.Member.MemberId);
        upload.append("MobileNo", this.Member.MobileNo);
        upload.append("EmailId", this.Member.EmailId);
        upload.append("TotalCount", 1);
        upload.append("TotalAmount", TotalAmount);

        upload.append("Website", this.Website);
        upload.append("TransferredStatus", TransferredStatus);
        upload.append("TransferredLomCode", this.TransferredLomCode);
        upload.append("HighestPosition", this.HighestPosition);
        upload.append("HighestPositionYear", this.HighestPositionYear);
        upload.append("ProfessionDetails", this.ProfessionDetails);
        upload.append("TrainerType", TrainerType);
        upload.append("AlreadyAttendedYear", this.AlreadyAttendedYear);
        upload.append("FailedStatus", FailedStatus);
        upload.append("ContributeDescription", this.ContributeDescription);
        upload.append("ZtwsExpectations", this.ZtwsExpectations);

        var EventData = {
          EducationDetails: this.EducationDetails,
          TrainingDetails: this.TrainingDetails,
          MemberDetails: this.Member,
        };

        upload.append("Form", JSON.stringify(EventData));

        var file = this.MemberImage;
        var FileName = file.name;
        console.log({ FileName });
        var FileType = file.type;
        console.log({ FileType });

        const { canvas } = this.$refs.cropper.getResult();
        if (canvas) {
          canvas.toBlob((blob) => {
            upload.append("ProfileImage", blob, FileName);
            this.submitForm(upload);
          }, FileType);
        } else {
          if (FileName) {
            message = "Kindly select image. ";
            this.sweetAlert("error", message, false);
          } else {
            upload.append("ProfileImage", null);
            this.submitForm(upload);
          }
        }
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        if (EventPlanId == "") {
          message += "Event plan id should not be empty. ";
        }
        // this.toast("error", message);
        this.sweetAlert("error", message, false);
      }
    },
    generateRequestData() {
      console.log("generateRequestData is called");
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      var selectedPlan = this.selectedPlan;
      var EventPlanId = selectedPlan.EventPlanId;
      EventPlanId = EventPlanId == (null || undefined) ? "" : EventPlanId;
      console.log("EventPlanId=" + EventPlanId);

      var TrainerType = this.TrainerType == "" ? 0 : this.TrainerType;
      if (EventPlanId != "" && validate1) {
        var TotalAmount = parseFloat(selectedPlan.TotalAmount);
        console.log("TotalAmount=" + TotalAmount);

        this.Member.StateId = this.StateCode;
        this.Member.DistrictId = this.DistrictCode;
        this.Member.CityId = this.CityCode;

        var TransferredStatus = this.TransferredStatus ? 1 : 0;
        var FailedStatus = this.FailedStatus ? 1 : 0;

        var upload = {
          UserInterface: 6,
          RegistrationType: 3,
          Event: this.EventId,
          Plan: EventPlanId,
          Lom: this.LomCode,
          Member: this.Member.MemberId,
          MobileNo: this.Member.MobileNo,
          EmailId: this.Member.EmailId,
          TotalCount: 1,
          TotalAmount: TotalAmount,
          Website: this.Website,
          TransferredStatus: TransferredStatus,
          TransferredLomCode: this.TransferredLomCode,
          HighestPosition: this.HighestPosition,
          HighestPositionYear: this.HighestPositionYear,
          ProfessionDetails: this.ProfessionDetails,
          TrainerType: TrainerType,
          AlreadyAttendedYear: this.AlreadyAttendedYear,
          SpouseAttendingStatus: FailedStatus,
          Expectations: this.ZtwsExpectations,
          ContributeDescription: this.ContributeDescription,
          Opinion: this.Opinion,
          EducationDetails: this.EducationDetails,
          TrainingDetails: this.TrainingDetails,
          MemberDetails: this.Member,
        };

        console.log("upload=" + JSON.stringify(upload));
        this.submitForm(upload);
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        if (EventPlanId == "") {
          message += "Event plan id should not be empty. ";
        }
        // this.toast("error", message);
        this.sweetAlert("error", message, false);
      }
    },
    submitForm(upload) {
      console.log("submitForm is called");

      this.ProgressLoadingFlag = true;

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/jci-program/order/create";
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );
      console.log("upload=" + JSON.stringify(upload));

      this.progessStart = 1;

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.ProgressLoadingFlag = false;
          thisIns.progessStart = 0;

          output = response.data.output;
          flag = response.data.flag;
          console.log("output=" + output + ", flag=" + flag);
          records = response.data.records;
          console.log({ records });

          if (flag == 1) {
            thisIns.Order = records.Order;
            thisIns.makePayment();
            // thisIns.sweetAlert("success", output, true);
          } else {
            // thisIns.toast("error", output);
            thisIns.sweetAlert("error", output, false);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.ProgressLoadingFlag = false;
        });
    },
    addNewRow(data) {
      console.log("addNewRow called");
      console.log("data=" + JSON.stringify(data));
      var dataFlag = data.InsertFlag;
      console.log("dataFlag=" + dataFlag);

      if (dataFlag) {
        this.EducationDetails.push(data);
        console.log(
          "EducationDetails=" + JSON.stringify(this.EducationDetails)
        );
      }
      this.addEducationDetailsPrompt = false;
    },
    deleteRow(index, tr) {
      console.log("deleteRow called");
      console.log({ index });
      console.log({ tr });
      if (index >= 0) {
        this.EducationDetails.splice(index, 1);
      }
    },
    makePayment() {
      console.log("makePayment");
      // ev.preventDefault();
      var order = this.Order;
      const thisIns = this;
      var rzp1 = new Razorpay({
        key: order.rzp_key,
        account_id: order.rzp_account_id,
        order_id: order.rzp_order_id,
        amount: order.rzp_amount,
        name: order.rzp_name,
        currency: "INR",
        description: order.rzp_description,
        image: order.rzp_image,
        theme: {
          color: order.rzp_color,
        },
        prefill: {
          name: order.customer_name,
          email: order.customer_email_id,
          contact: order.customer_mobile_no,
        },
        notes: {
          address: "",
        },
        // callback_url: "http://domain.com/#/about/" + this.order_id,
        redirect: false,
        handler: function (response) {
          console.log("razorpay_payment_id=" + response.razorpay_payment_id);
          thisIns.SubmitFlag = true;
          // thisIns.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
          thisIns.pageRedirect({}, "/order/success");
        },
      });
      rzp1.open();
    },
  },
  beforeMount() {
    var CurrentJciYearId = this.$session.get("CurrentJciYearId");
    // CurrentJciYearId = 11;
    CurrentJciYearId =
      CurrentJciYearId == (null || undefined) ? "" : CurrentJciYearId;
    console.log("CurrentJciYearId=" + CurrentJciYearId);
    this.JciYearCode = CurrentJciYearId;
    this.refreshPageData();
    /*
    this.tableColumns1.push(
      { text: "Description", value: "Description" },
      { text: "Subscription Fees", value: "AmountTxt" },
      { text: "Nos", value: "Qty" },
      { text: "Sub Total", value: "SubTotal" }
      // { text: "Actions", value: "actions" }
    );
    */
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */
#ztws-events-create {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  #faculty-table {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
      font-size: 12px;
      color: red;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
      font-size: 15px;
    }
  }
  #document-table {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
      font-size: 20px;
      color: skyblue;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
      font-size: 15px;
    }
  }
  .animate-button1 {
    color: white;

    animation: heartBeat; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 10s; /* don't forget to set a duration! */
  }
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }

  @import url(https://fonts.googleapis.com/css?family=Lato:700);
  $color: #8950fc;
  $color1: #191970;
  .cards {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0);
    flex: 1;
    margin: 8px;
    padding: 10px;
    padding-top: 35px;
    height: 370px;
    width: 225px;
    position: relative;
    text-align: center;
    transition: all 0.5s ease-in-out;
    border: solid 1px #4f1990;
  }
  .cards-box {
    height: 380px;
    margin-top: 10px;
  }
  .cards.popular {
    margin-top: -10px;
    height: 400px;
    padding: 20px;
    padding-top: 40px;
    width: 225px;
  }
  .cards-title-bar .cards-title {
    color: $color1;
    font-size: 18px;
    vertical-align: middle;
    text-transform: uppercase;
  }
  .cards.popular .cards-title-bar .cards-title {
    color: $color;
    vertical-align: middle;
    font-size: 20px;
  }
  .cards-price .cards-price-amount {
    color: $color1;
    font-size: 30px;
  }
  .cards.popular .cards-price .cards-price-amount {
    color: $color;
    font-size: 32px;
  }
  .cards.popular .cards-action .cards-action-button {
    background-color: $color;
    color: #fff;
    font-size: 16px;
    padding: 15px;
    height: 60px;
  }
  .cards.popular .cards-action .cards-action-button:hover {
    background-color: $color;
    font-size: 20px;
  }
  .cards:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.6);
  }
  .cards-ribbon {
    position: absolute;
    overflow: hidden;
    top: -10px;
    left: -10px;
    width: 114px;
    height: 112px;
  }
  .cards-ribbon span {
    position: absolute;
    z-index: 1;
    display: block;
    width: 160px;
    padding: 10px 0;
    background-color: #3498db;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    left: -35px;
    top: 25px;
    transform: rotate(-45deg);
  }
  .cards-ribbon::before,
  .cards-ribbon::after {
    position: absolute;
    content: "";
    display: block;
    border: 5px solid #2980b9;
    border-top-color: transparent;
    border-left-color: transparent;
  }
  .cards-ribbon::before {
    top: 0;
    right: 0;
  }
  .cards-ribbon::after {
    bottom: 0;
    left: 0;
  }
  .cards-title-bar .cards-subtitle {
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
  }
  .cards-price {
    margin: 30px 0 -28px 0;
  }
  .cards.popular .cards-price {
    margin: 30px 0 -28px 0;
  }
  .cards-price .cards-price-amount .cards-price-sypol {
    font-size: 17px;
    display: inline-block;
    margin-left: -20px;
    width: 10px;
    color: rgba(0, 0, 0, 0.3);
  }
  .cards-price .cards-price-amount .cards-price-validity {
    color: rgba(0, 0, 0, 0.3);
    display: block;
    font-size: 12px;
    text-transform: uppercase;
  }
  .cards-description .cards-description-content-bar {
    display: block;
    list-style: none;
    margin: 40px 0 25px 0;
    padding: 0;
  }
  .cards-description .cards-description-content {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    text-align: left;
    margin: 0 0 10px;
  }
  .cards-description .cards-description-content::before {
    content: "♦";
    padding: 0 5px 0 0;
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
  }
  .cards-action .cards-action-button {
    background: transparent;
    border: 2px solid $color;
    border-radius: 30px;
    color: $color;
    cursor: pointer;
    display: block;
    font-size: 15px;
    font-weight: bold;
    padding: 20px;
    width: 100%;
    height: 60px;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
  }
  .cards-action .cards-action-button:hover {
    background-color: $color;
    box-shadow: 0 2px 4px #4f1990;
    color: #fff;
    font-size: 17px;
  }
}
</style>